//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { createAction } from 'redux-actions';
import type { AssetTag } from '@dt/graphql-support/types';

export type AssetTagsState = $ReadOnlyArray<AssetTag>;

// $FlowFixMe
export const updateAssetTag = createAction<'ASSET_TAGS_UPDATE', _, _>(
  'ASSET_TAGS_UPDATE',
  (assetTag: $ReadOnlyArray<AssetTag>): $ReadOnlyArray<AssetTag> => assetTag,
);

export default createReducerCreator<AssetTagsState, AssetTag, _>({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(updateAssetTag.toString()));
