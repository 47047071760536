import { Button } from '@material-ui/core';
// @ts-ignore: @reach/router types
import { Link, useLocation } from '@reach/router';
import React, { useEffect, useState } from 'react';
import NavigationItemContent from './NavigationItemContent';
var NavigationItem = function (_a) {
    var baseUrl = _a.baseUrl, label = _a.label, to = _a.to, Icon = _a.Icon, _b = _a.isSvg, isSvg = _b === void 0 ? false : _b, _c = _a.isButton, isButton = _c === void 0 ? false : _c, onClick = _a.onClick;
    var location = useLocation();
    var _d = useState(false), isCurrent = _d[0], setCurrent = _d[1];
    useEffect(function () {
        if (baseUrl && location.pathname.indexOf(baseUrl) > -1) {
            setCurrent(true);
        }
        else {
            setCurrent(false);
        }
    }, [baseUrl, location.pathname]);
    if (isButton) {
        return (React.createElement(Button, { style: {
                background: 'none',
                border: 'none',
                padding: 0,
                margin: 0,
                cursor: 'pointer',
                minWidth: 0,
            }, onClick: onClick },
            React.createElement(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg })));
    }
    return (React.createElement(Link, { to: to },
        React.createElement(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg })));
};
export default NavigationItem;
