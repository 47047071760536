// @flow
import type { User } from './users';
import fetch, { parse } from '@dt/fetch';
import config from '@dt/config';
import { result } from './util';

export async function get(id: string): Promise<User> {
  if (!id || !id.length) {
    throw new Error('Invalid asset id');
  }

  return fetch(`${config.horizonApiBaseUrl}/v2/assets/${id}/asset_tags`)
    .then(parse)
    .then(result);
}
