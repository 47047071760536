//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { createAction } from 'redux-actions';
import type { MobileApplicationsListItem } from '@dt/graphql-support/types';

export type HorizonAppsState = $ReadOnlyArray<MobileApplicationsListItem>;

// $FlowFixMe
export const updateHorizonApp = createAction<'HORIZON_APPS_UPDATE', _, _>(
  'HORIZON_APPS_UPDATE',
  (app: $ReadOnlyArray<MobileApplicationsListItem>): $ReadOnlyArray<MobileApplicationsListItem> => app,
);

export default createReducerCreator<HorizonAppsState, MobileApplicationsListItem, _>({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(updateHorizonApp.toString()));
