import React from 'react';
import Box from '@material-ui/core/Box';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import { SvgIcon } from '@material-ui/core';
var currentRouteStyle = function (isCurrent) { return ({
    borderRadius: 12,
    backgroundColor: isCurrent ? palette.white : 'initial',
    color: isCurrent ? palette.brand30 : palette.gray30,
    fill: isCurrent ? palette.brand30 : palette.gray30,
    padding: '8px 12px',
    margin: 0,
    height: 38,
}); };
var NavigationItemContent = function (_a) {
    var Icon = _a.Icon, isCurrent = _a.isCurrent, label = _a.label, isSvg = _a.isSvg;
    return (React.createElement(React.Fragment, null, !Icon ? (React.createElement(Text, { variant: "titleS", style: currentRouteStyle(isCurrent) }, label)) : (React.createElement(Box, null, isSvg ? (React.createElement("div", { style: currentRouteStyle(isCurrent) },
        React.createElement(SvgIcon, null, Icon))) : (React.createElement(Box, { style: currentRouteStyle(isCurrent) },
        React.createElement(Icon, null)))))));
};
export default NavigationItemContent;
