// @flow
import {
  statusToFriendlyString,
  getLatestStatusObject,
  getTargetStatus,
} from '@dt/findings/targets/status';
import { findingSortChoiceObject } from '@dt/user-api/search_queries';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import endOfQuarter from 'date-fns/end_of_quarter';
import dateFormat from 'date-fns/format';
import getTime from 'date-fns/get_time';
import isWithinRange from 'date-fns/is_within_range';
import lastDayOfYear from 'date-fns/last_day_of_year';
import startOfQuarter from 'date-fns/start_of_quarter';
import startOfYear from 'date-fns/start_of_year';
import subDays from 'date-fns/sub_days';
import subQuarters from 'date-fns/sub_quarters';
import subYears from 'date-fns/sub_years';
import {
  curry,
  concat,
  every,
  filter,
  flatMap,
  get,
  getOr,
  gte,
  head,
  identity,
  includes,
  isEmpty,
  join,
  map,
  orderBy,
  pick,
  pipe,
  replace,
  some,
  stubTrue,
  toLower,
  toPairs,
  values,
  flatten,
} from 'lodash/fp';
import { default as FindingPriorityEnumValue } from '@dt/enums/FindingPriorityEnum';
import { default as SecurityTemplateImportanceTagEnumValue } from '@dt/enums/SecurityTemplateImportanceTagEnum';
import type { DateRange, MinAge } from '../actions/filterActions';
import type { FilterStore } from '../reducers/filters';
import type { Application } from '@dt/user-api/mobile_apps';
import { type FindingSortChoiceEnum } from '@dt/user-api/search_queries';
import type { ReleaseType } from '@dt/enums/MobileAppReleaseTypeEnum';
import type { SecurityFinding } from '@dt/findings/types';
import type { Target, TargetStatus } from '@dt/findings/types';
import type { FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';
import type { FindingPriorityEnum } from '@dt/enums/FindingPriorityEnum';
import type { SecurityTemplateSeverityEnum } from '@dt/enums/SecurityTemplateSeverityEnum';
import type { CompliancePolicyEnum } from '@dt/enums/CompliancePolicyEnum';
import type { SecurityTemplateImportanceTagEnum } from '@dt/enums/SecurityTemplateImportanceTagEnum';
import type {
  AssetTag,
  MobileApplicationsListItem,
} from '@dt/graphql-support/types';

export type FindingsCriteria = $ElementType<FilterStore, 'findingsCriteria'>;
export type FindingWithApp = SecurityFinding & {
  app: Application,
  horizonApp?: MobileApplicationsListItem,
  assetTags?: $ReadOnlyArray<AssetTag>,
  ...
};

// This is a string version of lodash's includes that makes flow happy
const strIncludes = (str: string) => (v: string) => v.indexOf(str) !== -1;

const rangeCases = {
  LAST_7_DAYS: (s: TargetStatus): boolean =>
    isWithinRange(s.date, sevenDaysAgo(), new Date()),
  LAST_30_DAYS: (s: TargetStatus): boolean =>
    isWithinRange(s.date, thirtyDaysAgo(), new Date()),
  LAST_90_DAYS: (s: TargetStatus): boolean =>
    isWithinRange(s.date, ninetyDaysAgo(), new Date()),
  LAST_QUARTER: (s: TargetStatus): boolean =>
    isWithinRange(s.date, startOfLastQuarter(), endOfLastQuarter()),
  LAST_YEAR: (s: TargetStatus): boolean =>
    isWithinRange(s.date, startOfLastYear(), endOfLastYear()),
  CUSTOM: (s: TargetStatus, d: DateRange): boolean => {
    if (!d.from || !d.to)
      throw new Error('Invalid range case for date filter.');

    return isWithinRange(s.date, d.from, d.to);
  },
};

/**
 * Checks if Status is within give DateRange.
 */
export const isStatusWithinRange = (
  dateRange: DateRange,
  s: TargetStatus,
): boolean => getOr(stubTrue, [dateRange.type], rangeCases)(s, dateRange);

/**
 * Returns "true" if Status is either among selected ones
 * or no status is selected at all.
 */
export const hasAnySelectedStatus = (
  statuses: $ReadOnlyArray<FindingTargetStatusEnum>,
  s: TargetStatus,
): boolean =>
  isEmpty(statuses) || includes('ANY', statuses)
    ? true
    : includes(s.status, statuses);

export const filterByStatus = (
  v: $ElementType<
    $ElementType<FilterStore, 'findingsCriteria'>,
    'selectedStatuses',
  >,
  f: SecurityFinding,
): boolean =>
  pipe(
    getOr([], ['targets']),
    flatMap(getOr([], ['statuses'])),
    some(
      s =>
        hasAnySelectedStatus(v.statuses, s) &&
        isStatusWithinRange(v.dateRange, s),
    ),
  )(f);

/**
 * Returns "true" if Findings has any Target with the
 * latest status among the selected statuses or if the
 * selected statuses list is empty.
 */
export const filterByCurrentStatus = (
  v: $ElementType<
    $ElementType<FilterStore, 'findingsCriteria'>,
    'selectedCurrentStatuses',
  >,
  f: SecurityFinding,
): boolean =>
  pipe(
    getOr([], ['targets']),
    flatMap(t => head(getOr([], ['statuses'], t))),
    some(s =>
      isEmpty(v) || includes('ANY', v) ? true : includes(s.status, v),
    ),
  )(f);

/**
 * Returns "true" if Severity is either among selected ones
 * or no severity is selected at all
 */
export const filterBySeverity = (
  v: $ReadOnlyArray<SecurityTemplateSeverityEnum>,
  f: SecurityFinding,
): boolean =>
  isEmpty(v) || includes('ANY', v) ? true : includes(f.severity || '', v); // <-- flow

/**
 * Returns "true" if CompliancePolicy is either among selected ones
 * or no compliance policy is selected at all
 */
export const filterByCompliancePolicy = (
  v: $ReadOnlyArray<CompliancePolicyEnum>,
  f: SecurityFinding,
): boolean =>
  isEmpty(v) || includes('ANY', v)
    ? true
    : pipe(
        getOr([], ['compliance_policy_references']),
        some(r => includes(r.compliance_policy, v)),
      )(f);

export const filterByKeywords = (
  v: $ElementType<
    $ElementType<FilterStore, 'findingsCriteria'>,
    'selectedKeywords',
  >,
  f: SecurityFinding,
): boolean => {
  const value = v?.toLowerCase();
  if (!value) return true;

  const title = f.title.toLowerCase();
  const description = f.description?.toLowerCase() || '';
  const description_intro = f.description_intro?.toLowerCase() || '';
  const target_texts = f.targets.map(t => t.raw_text?.toLowerCase() || '');

  return [title, description, description_intro, ...target_texts].some(
    t => t?.indexOf(value) >= 0,
  );
};

/**
 * Returns "true" if Priority is either among selected ones
 * or no priority is selected at all.
 */
export const filterByPriority = (
  v: $ReadOnlyArray<FindingPriorityEnum>,
  f: SecurityFinding,
): boolean =>
  isEmpty(v) || includes('ANY', v) ? true : includes(f.priority || '', v); // <-- flow

const storeBlockersToTag: {| google: 'GOOGLE_P1', apple: 'APPLE_P1' |} = {
  google: 'GOOGLE_P1',
  apple: 'APPLE_P1',
};
/**
 * Returns "true" if Store blockers is either among selected ones
 * or no store blocker is selected at all.
 */
const filterByStoreBlockers = (
  v: $ReadOnlyArray<SecurityTemplateImportanceTagEnum>,
  f: SecurityFinding,
): boolean =>
  isEmpty(v) || includes('ANY', v)
    ? true
    : Boolean(
        v.find(vx => {
          const importance_tags = f.importance_tags || [];
          return (
            importance_tags.includes(storeBlockersToTag[vx]) ||
            importance_tags.includes(vx)
          );
        }),
      );

/**
 * Returns "true" if Finding's age is greater than given age
 * or if there is no given age
 */
export const filterByMinAge = (v: MinAge, f: SecurityFinding): boolean =>
  v === '' || v === 0 ? true : gte(calcAging(f), Number(v));

/**
 * Returns "true" if the given text appears in either
 * "title" or "description" of Finding
 */
export const filterByText = (v: string, f: SecurityFinding): boolean =>
  pipe(
    pick(['title', 'description', 'id']),
    values,
    map(toLower),
    some(strIncludes(toLower(v))),
  )(f);

/**
 * Returns "true" if the Finding's "release_type" is among
 * the selected ones or there is no release_type is selected
 */
export const filterByReleaseType = (
  v: $ReadOnlyArray<ReleaseType>,
  f: FindingWithApp,
): boolean =>
  isEmpty(v) || includes('ANY', v)
    ? true
    : pipe(get(['app', 'release_type']), r => includes(r, v))(f);

// fn :: v -> obj -> bool
const filterFns = {
  selectedKeywords: filterByKeywords,
  selectedPriorities: filterByPriority,
  selectedStoreBlockers: filterByStoreBlockers,
  selectedSeverities: filterBySeverity,
  selectedCompliancePolicies: filterByCompliancePolicy,
  selectedStatuses: filterByStatus,
  selectedCurrentStatuses: filterByCurrentStatus,
  selectedReleaseTypes: filterByReleaseType,
  minAge: filterByMinAge,
};

/**
 * filterFns and state are joined on their common key
 * and then values from state are applied to filterFns
 * to determine if the finding needs to be filtered.
 *
 * Example:
 *  filterFns: { status:  (v, obj) => obj.status === v }
 *  store: { status: 'OPEN'}
 *  filterFns['status'](state['status'], finding) -> bool
 * @param {*} store
 * @param {*} findings
 */
export const filterFindings = (
  store: $ElementType<FilterStore, 'findingsCriteria'>,
  findings: $ReadOnlyArray<FindingWithApp>,
) =>
  filter(
    o =>
      every(
        identity,
        toPairs(store).map(([k, v]) => getOr(stubTrue, k, filterFns)(v, o)),
      ),
    findings,
  );

/**
 * Target -> Bool
 * Whether a target is still OPEN or NEW
 */
export const isTargetOpen: Target => boolean = pipe(
  getOr([], 'statuses'),
  head,
  getOr('UNKNOWN_STATUS', 'status'),
  s => includes(s, ['NEW', 'OPEN']),
);

/**
 *
 * Finding -> Bool
 * Whether a Finding has at least one OPEN
 * or NEW Target
 */
export const isFindingOpen = (f: {
  +targets: $ReadOnlyArray<Target>,
  ...
}): boolean => some(identity, map(isTargetOpen, f.targets));

/**
 * Gets last "status" of each "target" and finds the
 * latest one and returns its date.
 *
 * In other word, when was the last time this specific
 * findings was touched/updated.
 */
export const latestStatusDate: ({
  +targets: $ReadOnlyArray<Target>,
  ...
}) => Date = pipe(
  getOr([], 'targets'),
  map(t => head(t.statuses)),
  orderBy([o => getTime(o.date)], ['desc']),
  head,
  get('date'),
);

/**
 * Calculates the age based on whether the Findings is still
 * "open" or not.
 *  - open: (now - the date is was first discovered)
 *  - close: (last time it was updated - the date is was first discovered)
 *
 * For invalid dates we get "NaN". Hence the last "|| 0" is to make sure
 * we get a number eventually.
 */
const calcAging = (f: {
  +date_created: Date | string,
  +targets: $ReadOnlyArray<Target>,
  ...
}): number =>
  (isFindingOpen(f)
    ? differenceInCalendarDays(new Date(), f.date_created || 0) // <- flow
    : differenceInCalendarDays(latestStatusDate(f), f.date_created || 0)) || 0; // <- flow

/**
 * Finding -> Number
 * Used to sort findings by Priority
 */
const priorityValues = { P0: 5, P1: 4, P2: 3, P3: 2, P4: 1 };
export const compareByPriority = (f: {
  +priority?: ?FindingPriorityEnum,
  ...
}): number => getOr(0, f.priority || '', priorityValues); // <- flow

const severityValues = { HIGH: 3, MEDIUM: 2, LOW: 1 };
export const compareBySeverity = (f: {
  +severity: SecurityTemplateSeverityEnum,
  ...
}): number => getOr(0, f.severity || '', severityValues); // <- flow

export const compareByDate = (f: {
  +date_created: Date | string,
  ...
}): number => getTime(getOr(0, 'date_created', f));

const statusValues = { NEW: 5, OPEN: 4 };
export const compareByStatus = (f: {
  +aggregated_status: FindingTargetStatusEnum,
  ...
}): number => getOr(0, f.aggregated_status || '', statusValues); // <- flow

export const calcScore = (f: SecurityFinding): number =>
  getOr(0, f.severity || '', severityValues) +
  getOr(0, f.priority || '', priorityValues);

const compareByAge = calcAging;

const sortFns = {
  [findingSortChoiceObject.SEVERITY]: compareBySeverity,
  [findingSortChoiceObject.PRIORITY]: compareByPriority,
  [findingSortChoiceObject.STATUS]: compareByStatus,
  [findingSortChoiceObject.DATE_CREATED]: compareByDate,
  [findingSortChoiceObject.AGE]: compareByAge,
  [findingSortChoiceObject.NONE]: () => 0,
};

const doesHaveSecurityP1 = (f: FindingWithApp): string => {
  return includes('SEVERITY_P1', getOr([], ['importance_tags'], f))
    ? 'Yes'
    : 'No';
};

const doesHaveGooglePlayBlocker = (f: FindingWithApp): string => {
  return includes('GOOGLE_P1', getOr([], ['importance_tags'], f))
    ? 'Yes'
    : 'No';
};

const doesHaveAppStoreBlocker = (f: FindingWithApp): string => {
  return includes('APPLE_P1', getOr([], ['importance_tags'], f)) ? 'Yes' : 'No';
};

const constructPortalUrl = (f: FindingWithApp): string =>
  f.mobile_app_id
    ? `https://www.securetheorem.com/app/${f.mobile_app_id}/issues/${f.id}`
    : '';

/**
 * Returns an array of values from properties of SecurityFinding and Target
 * that we need to put into CSV file.
 *
 */
export const getFindingAndTargetValues = (
  f: FindingWithApp,
): $ReadOnlyArray<$ReadOnlyArray<string>> => {
  let curried = curry(getFindingAndTargetValuesHelper)(f);
  return pipe(map(curried))(f.targets);
};

/**
 * The function that does all the heavy lifting of actually extracting values
 *  - adds default value if property doesn't exist
 *  - encloses the values for CSV
 */
export const getFindingAndTargetValuesHelper = (
  f: FindingWithApp,
  t: Target,
): $ReadOnlyArray<string> => {
  const latestStatusObject = getLatestStatusObject(t);
  const latestStatusDate = latestStatusObject ? latestStatusObject.date : '';
  return pipe(
    ({ f, t }) => ({
      title: getOr('-- no title --', ['title'], f),
      app_name: getOr('-- n/a --', ['app', 'name'], f),
      platform: getOr('-- n/a --', ['app', 'platform'], f),
      bundle_id: getOr('', ['app', 'bundle_id'], f),
      priority: getOr('', ['priority'], f),
      severity: getOr('', ['severity'], f),
      security_p1: doesHaveSecurityP1(f),
      google_play_blocker: doesHaveGooglePlayBlocker(f),
      app_store_blocker: doesHaveAppStoreBlocker(f),
      cvss_score: getOr('0', ['cvss_score'], f),
      cvss_vector: getOr('', ['cvss_vector'], f),
      affected_component: getOr('', ['formatted_text'], t),
      description: extractDescription(f),
      recommendation: getOr('', ['recommendation'], f),
      secure_code: getOr('-- n/a --', ['secure_code'], f),
      target_id: getOr('', ['id'], t),
      date_opened: getOr('', ['date_created'], t),
      current_status: statusToFriendlyString(getTargetStatus(t)),
      current_status_date: latestStatusDate,
      mobile_app_id: getOr('', ['mobile_app_id'], f),
      finding_id: getOr('-- no id --', ['id'], f),
      portal_url: constructPortalUrl(f),
      asset_tags: !f.assetTags
        ? ''
        : f.assetTags
            .map(at => `${at.tag}: ${at.value ? at.value : ''}`)
            .join('\n'),
      compliance_policy_references: !f.compliance_policy_references
        ? ''
        : f.compliance_policy_references
            ?.map(item => `${item.compliance_policy}: ${item.markdown}`)
            .join('\n'),
    }),
    values,
    map(encloseCSVValue),
  )({ f, t });
};

const extractDescription: FindingWithApp => string = pipe(
  pick(['description_intro', 'description']),
  values,
  filter(identity),
  join('\n'),
);

/** Header row for CSV output */
const CSV_HEADER: $ReadOnlyArray<string> = [
  'Issue Title',
  'App Name',
  'Platform',
  'Bundle ID',
  'Priority',
  'Severity',
  'Security P1',
  'Google Play Blocker',
  'App Store Blocker',
  'CVSS Score',
  'CVSS Vector',
  'Affected Component',
  'Description',
  'Recommendation',
  'Secure Code',
  'Issue ID',
  'Date Opened',
  'Current Status',
  'Current Status Date',
  'Mobile App ID',
  'Security Finding ID',
  'Portal URL',
  'Asset Tags',
  'Compliance Policy',
];

/** Data URL's mime type. See specs at https://tools.ietf.org/html/rfc4180#section-3 */
const CSV_MIME_TYPE: string = 'text/csv;charset=utf-8;header=present,';

/**
 * Gets all the findings' values, append the header
 * row and returns a CSV string
 */
export const findingsToCSV: ($ReadOnlyArray<FindingWithApp>) => string = pipe(
  map(getFindingAndTargetValues),
  flatten,
  concat([CSV_HEADER]),
  map(join(',')),
  join('\n'),
);

export const getCSVBlob = (data: string): Blob =>
  new Blob([data], { type: CSV_MIME_TYPE });

/** Check if there is a comma, quote or a new line in string */
export const needsQuotes = (s: string): boolean => /^.*[,\n"\r\n].*$/gm.test(s);

/**
 * Escapes a double quote by appending another double quote
 *
 * > If double-quotes are used to enclose fields, then a double-quote
     appearing inside a field must be escaped by preceding it with
     another double quote.  For example:

     "aaa","b""bb","ccc"

  See spec at https://tools.ietf.org/html/rfc4180#section-2
 */
export const escapeQuote = (s: string): string => replace(/"/gm, '""', s);

/** Just wraps in a double quote */
const wrapInQuote = (s: string): string => `"${s}"`;

/* Given Date in string, returns string in MM-DD-YYYY format */
export const isoStringToDate = (d: string): string =>
  dateFormat(d, 'MM-DD-YYYY');

/**
 * Encloses a string with double quote if there is a comma, newline
 * or a double quote present in the content. It also escapes double
 * quote as mentioned in the specification.
 *
 * See spec at https://tools.ietf.org/html/rfc4180#section-2
 */
export const encloseCSVValue = (s: string): string =>
  needsQuotes(s) ? wrapInQuote(escapeQuote(s)) : s;

export const sortFindings = <
  T: {
    +date_created: Date | string,
    +targets: $ReadOnlyArray<Target>,
    +priority?: ?FindingPriorityEnum,
    +severity?: SecurityTemplateSeverityEnum,
    +date_created: Date | string,
    +aggregated_status?: FindingTargetStatusEnum,
    ...
  },
>(
  sortBy: FindingSortChoiceEnum | '',
  findings: $ReadOnlyArray<T>,
): $ReadOnlyArray<T> =>
  orderBy(getOr(compareByStatus, sortBy, sortFns), ['desc'], findings);

export const searchFindings = (
  text: string,
  findings: $ReadOnlyArray<FindingWithApp>,
): $ReadOnlyArray<FindingWithApp> =>
  filter(f => filterByText(text, f), findings);

const startOfLastYear = (now: Date = new Date()): Date =>
  startOfYear(subYears(now, 1));

const endOfLastYear = (now: Date = new Date()): Date =>
  lastDayOfYear(subYears(now, 1));

const startOfLastQuarter = (now: Date = new Date()): Date =>
  startOfQuarter(subQuarters(now, 1));

const endOfLastQuarter = (now: Date = new Date()): Date =>
  endOfQuarter(subQuarters(now, 1));

const thirtyDaysAgo = (now: Date = new Date()): Date => subDays(now, 30);

const ninetyDaysAgo = (now: Date = new Date()): Date => subDays(now, 90);

const sevenDaysAgo = (now: Date = new Date()): Date => subDays(now, 7);

export const getCustomRange = (now: Date = new Date()): DateRange => ({
  type: 'CUSTOM',
  from: subYears(now, 1),
  to: now,
});

export function replaceAnyFilterWithEmptyArray<T>(
  target: $ReadOnlyArray<T>,
): $ReadOnlyArray<T> {
  return target.includes('ANY') ? [] : target;
}

export function replaceAnyFilterWithEmptyArrayForImportanceTags<T>(
  target: $ReadOnlyArray<T>,
): $ReadOnlyArray<T | SecurityTemplateImportanceTagEnum> {
  if (target.includes('ANY')) {
    return [];
  }

  let targetWithBlockers = [...target];
  if (window.location.pathname.includes('priority')) {
    if (
      targetWithBlockers.indexOf(
        SecurityTemplateImportanceTagEnumValue.GOOGLE_P1,
      ) === -1
    ) {
      targetWithBlockers.push(SecurityTemplateImportanceTagEnumValue.GOOGLE_P1);
    }

    if (
      targetWithBlockers.indexOf(
        SecurityTemplateImportanceTagEnumValue.APPLE_P1,
      ) === -1
    ) {
      targetWithBlockers.push(SecurityTemplateImportanceTagEnumValue.APPLE_P1);
    }

    if (
      targetWithBlockers.indexOf(
        SecurityTemplateImportanceTagEnumValue.SECURITY_P1,
      ) === -1
    ) {
      targetWithBlockers.push(
        SecurityTemplateImportanceTagEnumValue.SECURITY_P1,
      );
    }
  }

  return targetWithBlockers;
}

export function replaceAnyFilterWithEmptyArrayForPriorities<T>(
  target: $ReadOnlyArray<T>,
): $ReadOnlyArray<T | FindingPriorityEnum> {
  if (target.includes('ANY')) {
    return [];
  }

  let targetWithBlockers = [...target];
  if (
    window.location.pathname.includes('priority') &&
    targetWithBlockers.indexOf(FindingPriorityEnumValue.NO_PRIORITY) === -1
  ) {
    targetWithBlockers.push(FindingPriorityEnumValue.NO_PRIORITY);
  }

  return targetWithBlockers;
}
