// @flow
import { call, put, spawn, takeEvery } from 'redux-saga/effects';
import { Raven } from '@dt/global';
import { callPromise } from '@dt/redux-saga-wrapped-effects';

import { updateHorizonApp } from '../reducers/horizonApps';
import { updateApp } from '../reducers/apps';
import { list, patch, type Applications, listHorizon } from '@dt/user-api/mobile_apps';
import { withProgressIndicator } from '@dt/progress-indicator';
import { callSaga } from '@dt/redux-saga-wrapped-effects';
import paginate, { paginateToEnd } from './util/paginate';
import {
  appFileSubmitted,
  uploadButtonClicked,
  appFileRejected,
  appFileAccepted,
  appXCUITestFileAccepted,
  appXCUITestFileRejected,
  startedLoadingApps,
  finishedLoadingApps,
  receivedAppsError,
  startedLoadingHorizonApps,
  finishedLoadingHorizonApps,
  receivedHorizonAppsError,
  appXCUITestFileSubmit,
} from '../actions';
import { billingAction } from '@dt/billing-info';
import { browserHistory } from '../util/history';
import app_uploads from '@dt/user-api/app_uploads';
import * as uploadXCUITestService from '@dt/user-api/xcuitest_uploads';
import type { Application, ListHorizonParams, ListParams } from '@dt/user-api/mobile_apps';
import { select } from '@dt/redux-saga-wrapped-effects';
import { type Saga } from 'redux-saga';
import { getUserAccount } from '@dt/session';
import { type ReleaseType } from '@dt/enums/MobileAppReleaseTypeEnum';
import type { State } from '../store_state_type';
import { updateAppLinkingStarted, updateAppLinkingSuccess, updateAppLinkingFailure } from '../actions/mobileApps';
import { MobileAppReleaseTypeValues } from '@dt/graphql-support/types';
import type { MobileAppReleaseType } from '@dt/graphql-support/types';
import type { MobileApplicationsListItem } from '@dt/graphql-support/types';
import { get } from '@dt/user-api/asset_tags';
import { updateAssetTag } from '../reducers/assetTags';

const TYPE = 'apps';

export default function* appsFlow(): Saga<void> {
  yield spawn(loadAppsAtLogin);
  yield spawn(watchForUploadButtonClicked);
  yield spawn(watchForFileSubmitted);
  yield spawn(watchForXCUITestFileSubmitted);
  yield spawn(watchForAppLinking);

  yield takeEvery(billingAction.redirectToPurchaseQuoteAction.toString(), function*() {
    yield call(browserHistory.push, '/pricing');
  });
}

function* loadApps(params: ListParams): Saga<void | Applications> {
  const apps = yield* callSaga(paginate, TYPE, params, function*(params: ?ListParams) {
    return yield* callPromise(list, params);
  });

  if (apps && apps.mobile_apps && apps.mobile_apps.length) {
    yield put(updateApp(apps.mobile_apps));
    return apps.mobile_apps;
  }
}

function* loadHorizonApps(params?: ListHorizonParams): Saga<void | $ReadOnlyArray<MobileApplicationsListItem>> {
  const response = yield* callSaga(paginate, TYPE, params, function*(params: ?ListHorizonParams) {
    return yield* callPromise(listHorizon, params);
  });

  if (response && response.mobile_applications && response.mobile_applications.length) {
    yield put(updateHorizonApp(response.mobile_applications));

    for (const ma of response.mobile_applications) {
      const result = yield call(get, ma.id);
      result.asset_tags.map(
        t => (t['sevenhell_customer_mobile_app_id'] = parseInt(ma.sevenhell_customer_mobile_app_id, 10)),
      );

      yield put(updateAssetTag(result.asset_tags));
    }

    return response.mobile_applications;
  }
}

function* loadAllApps(params: ListParams = {}): Saga<void | Applications> {
  return yield* withProgressIndicator(function*() {
    yield put(startedLoadingApps());
    try {
      return yield* callSaga(paginateToEnd, loadApps, TYPE, params, params);
    } catch (err) {
      yield put(receivedAppsError());
      throw err;
    } finally {
      yield put(finishedLoadingApps());
    }
  }, TYPE);
}

function* loadAllHorizonApps(params: ListHorizonParams = {}): Saga<void | $ReadOnlyArray<MobileApplicationsListItem>> {
  return yield* withProgressIndicator(function*() {
    yield put(startedLoadingHorizonApps());
    try {
      return yield* callSaga(paginateToEnd, loadHorizonApps, TYPE, params, params);
    } catch (err) {
      yield put(receivedHorizonAppsError());
      throw err;
    } finally {
      yield put(finishedLoadingHorizonApps());
    }
  }, TYPE);
}

export function* loadAppsAtLogin(): Saga<void> {
  const userAccount = yield* callPromise(getUserAccount);

  if (userAccount.no_session_reason) {
    // No need to fetch apps if not logged in
    return;
  }

  const { accountInfo } = userAccount;

  const { apps, horizonApps } = yield* select((state: State) => state);

  if (!accountInfo.toggles || !accountInfo.toggles.scan_and_secure) {
    return;
  }

  if (!apps || apps.length < 1) {
    yield call(loadAllApps, { filter: 'SCAN_AND_SECURE_APPS' });
  }

  if (!horizonApps || horizonApps.length < 1) {
    yield call(loadAllHorizonApps, { page_size: '100' });
  }
}

function* watchForUploadButtonClicked(): Saga<void> {
  yield takeEvery(uploadButtonClicked.toString(), function*(): Saga<void> {
    yield call(browserHistory.push, '/upload');
  });
}

function* watchForFileSubmitted(): Saga<void> {
  yield takeEvery(appFileSubmitted.toString(), function*(action: {
    payload: {
      file: File,
      username: string,
      password: string,
      comments: string,
      isEnterpriseInternal: boolean,
      linkedAppStoreAppId: ?string,
      ...
    },
    ...
  }): Saga<void> {
    const { file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId } = action.payload;
    yield* uploadFile(file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId);
  });
}

export type UploadAppResponse = {|
  bundle_id: string,
  is_app_new: boolean,
  name: string,
  platform: string,
  session_id: string,
  status: string,
  release_type: MobileAppReleaseType,
|};

function* uploadFile(
  file: File,
  username: ?string,
  password: ?string,
  comments: ?string,
  isEnterpriseInternal: ?boolean,
  linkedAppStoreAppId: ?string,
): Saga<void> {
  try {
    const upload_url = yield* callPromise(app_uploads.create);

    if (typeof upload_url !== 'string') {
      throw new Error('POST file upload did not return a response');
    }

    const form = new FormData();
    form.append('file', file);
    form.append('source', 'DT_UPLOAD_FORM');

    if (username) {
      form.append('username', username);
    }
    if (password) {
      form.append('password', password);
    }
    if (comments) {
      form.append('comments', comments);
    }
    if (typeof isEnterpriseInternal === 'boolean' && isEnterpriseInternal) {
      form.append('release_type', MobileAppReleaseTypeValues.ENTERPRISE);
    } else {
      form.append('release_type', MobileAppReleaseTypeValues.PRE_PROD);
    }
    if (linkedAppStoreAppId) {
      form.append('linked_app_store_mobile_app_id', linkedAppStoreAppId);
    }

    const response = yield* callPromise(fetch, upload_url, {
      method: 'POST',
      body: form,
    });
    const body = yield* callPromise(async () => response.json());

    if (body.error) {
      yield put(appFileRejected(body.error));
      return;
    }

    yield put(appFileAccepted((body: UploadAppResponse)));
  } catch (err) {
    if (err instanceof Error) {
      yield put(appFileRejected(err.message));
      Raven.captureException(err);
      return;
    }
    throw err;
  }
}

function* watchForXCUITestFileSubmitted(): Saga<void> {
  yield takeEvery(appXCUITestFileSubmit.toString(), function*(action: {
    payload: {
      file: File,
      bundle_id: string,
      release_type: ReleaseType,
      version: string,
      ...
    },
    ...
  }): Saga<void> {
    const { file, bundle_id, release_type, version } = action.payload;
    yield* uploadXCUITestFile(file, bundle_id, release_type, version);
  });
}

function* uploadXCUITestFile(file: File, bundle_id: string, release_type: ReleaseType, version: string): Saga<void> {
  try {
    const url = yield* callPromise(uploadXCUITestService.create, {});

    if (typeof url !== 'string') {
      throw new Error('POST file upload did not return a response');
    }

    const form = new FormData();
    form.append('file', file);
    form.append('source', 'DT_APP_XCUITEST_FORM');
    form.append('bundle_id', bundle_id);
    form.append('release_type', release_type);
    form.append('version', version);

    const response: Response = yield call(fetch, url, {
      method: 'POST',
      body: form,
    });

    // fetch don't throw unless is network error
    if (!response.ok) {
      // Better response? AppUploader actually returns a nice explaination why it failed
      throw new Error('Upload failed.');
    }

    yield put(appXCUITestFileAccepted(response));
  } catch (err) {
    // $FlowFixMe Flow85
    yield put(appXCUITestFileRejected(err));
  }
}

function* watchForAppLinking(): Saga<void> {
  yield takeEvery(updateAppLinkingStarted.toString(), function*(action: {
    type: string,
    payload: {
      app: Application,
      isEnterpriseInternal: boolean,
      linkedAppStoreAppId: ?string,
      ...
    },
    ...
  }): Saga<void> {
    const { app, isEnterpriseInternal, linkedAppStoreAppId } = action.payload;
    yield* withProgressIndicator(function*(): Saga<void> {
      try {
        // TODO: Sevenhell does not yet support updating an app's release type e.g. removing an app from the ENTERPRISE
        // category. We still support the deprecated functionality, temporarily.
        const updatedApp = yield* callPromise(patch, app.id, {
          is_enterprise_internal: isEnterpriseInternal,
          linked_app_store_mobile_app_id: linkedAppStoreAppId,
        });

        if (updatedApp && updatedApp.id === app.id) {
          yield put(updateApp([updatedApp]));
          yield put(updateAppLinkingSuccess(updatedApp));
        }
      } catch (e) {
        yield put(updateAppLinkingFailure(e));
      }
    });
  });
}
